/// <reference types="vite/client" />

import './style.css';
import { Feature, Map, View } from 'ol';
import { FeatureLike } from 'ol/Feature.js';
import { ReadOptions } from 'ol/format/Feature.js';
import MVT from 'ol/format/MVT.js';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import { Polygon } from 'ol/geom.js';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import { VectorTile } from 'ol/source.js';
import VectorSource from 'ol/source/Vector.js';
import WMTS, { Options, optionsFromCapabilities } from 'ol/source/WMTS.js';
import Fill from 'ol/style/Fill.js';
import Stroke from 'ol/style/Stroke.js';
import Style from 'ol/style/Style.js';
import Text from 'ol/style/Text.js';
import { ViewOptions } from 'ol/View.js';
import Pako from 'pako';

declare global {
	interface Window { olmap: Map }
}

const viewOptions: ViewOptions = { center: [0, 0], zoom: 2 };
const vectorSource: VectorSource = new VectorSource();
const d: string | undefined = new URLSearchParams(window.location.search).get("d")?.replace(/ /g, "+");
if (d) {
	const data: number[] = Array.from(new Float32Array(Uint8Array.from(atob(d), (v) => v.codePointAt(0)!).buffer));
	const origin: number[] = data.slice(0, 2);
	const offsets: number[] = data.slice(2);
	viewOptions['center'] = origin;
	viewOptions['zoom'] = 16;
	const polygon: number[][] = [];
	for (let i = 0; i < offsets.length; i += 2) {
		polygon.push([offsets[i]! + origin[0]!, offsets[i + 1]! + origin[1]!]);
	}
	vectorSource.addFeature(new Feature({
		name: "Work Site",
		geometry: new Polygon([polygon])
	}));
}

class GzippedMVT extends MVT {
	static GZIP_SIGNATURE = 0x8b1f;
	override readFeatures(source: ArrayBuffer, options: ReadOptions | undefined) {
		if (new Uint16Array(source.slice(0, 2))[0] == GzippedMVT.GZIP_SIGNATURE) {
			return super.readFeatures(Pako.inflate(source).buffer, options);
		} else {
			return super.readFeatures(source, options);
		}
	}
}

const naip_layer: TileLayer<WMTS> = new TileLayer();
function vectorStyle(feature: FeatureLike) {
	return new Style({
		text: new Text({
			font: "bold 14pt sans-serif",
			placement: "line",
			text: feature.get('name'),
			fill: new Fill({
				color: "#fff"
			}),
			stroke: new Stroke({
				color: "#000",
				width: 3
			}),
			declutterMode: "declutter"
		})
	});
}

fetch("https://gis.apfo.usda.gov/arcgis/rest/services/NAIP/USDA_CONUS_PRIME/ImageServer/WMTS/1.0.0/WMTSCapabilities.xml")
	.then((response) => response.text())
	.then((text) => {
		const wmtsOptions: Options = optionsFromCapabilities(
			new WMTSCapabilities().read(text), {
			layer: "NAIP_USDA_CONUS_PRIME",
			matrixSet: "EPSG:3857",
		})!;
		wmtsOptions.attributions = "Imagery: NAIP.";
		naip_layer.setSource(new WMTS(wmtsOptions));
	});

window.olmap = new Map({
	target: 'map',
	layers: [
		naip_layer,
		new VectorTileLayer({
			source: new VectorTile({
				format: new GzippedMVT(),
				url: import.meta.env.VITE_MVT_URL,
				attributions: "Features: &copy; OpenMapTiles &copy; OpenStreetMap contributors.",
				maxZoom: 14
			}),
			style: vectorStyle,
			declutter: true
		}),
		new VectorLayer({
			source: vectorSource,
			style: new Style({
				stroke: new Stroke({
					color: "#0f0"
				}),
				fill: new Fill({
					color: "#00ff001f"
				})
			})
		})
	],
	view: new View(viewOptions)
});